import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/analytics'
import 'firebase/storage'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // DEV
  apiKey: 'AIzaSyCPxsvCUKCdhH4C5lugoJ1xjMoz0bvm0bg',
  authDomain: 'masungi-360-dev.firebaseapp.com',
  databaseURL: 'https://masungi-360-dev.firebaseio.com',
  projectId: 'masungi-360-dev',
  storageBucket: 'masungi-360-dev.appspot.com',
  messagingSenderId: '220627252688',
  appId: '1:220627252688:web:e32d129d609b6e380935d5',
  measurementId: 'G-3Y6LTBRLF4'

  // PROD
  // apiKey: 'AIzaSyD1UyoDXnpBWUnJVqbCOsiDAWFWicil9jw',
  // authDomain: 'masungi-360.firebaseapp.com',
  // databaseURL: 'https://masungi-360.firebaseio.com',
  // projectId: 'masungi-360',
  // storageBucket: 'masungi-360.appspot.com',
  // messagingSenderId: '623318517976',
  // appId: '1:623318517976:web:22224dcc6d78a13d1c6fad',
  // measurementId: 'G-FJ1DVM2ZTX'
}

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)

// To apply the default browser preference instead of explicitly setting it.
firebase.auth().useDeviceLanguage()

// Start firebase analytics
firebase.analytics()

export const fbAuth = firebase.auth
export const fbDB = firebase.firestore
export const fbFuncs = app.functions('asia-east2')
export const fbAnalytics = firebase.analytics
export const fbDate = firebase.firestore.Timestamp.fromDate
export const fbStorage = firebase.storage()
