import React from 'react'
import FrontsiteLayout from '../components/layout/FrontsiteLayout'
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined'

const useStyles = makeStyles(theme =>
  createStyles({
    section: {
      [theme.breakpoints.up('md')]: {
        height: 'calc(100vh - 64px)'
      }
    },
    gridContainer: {
      height: '100%'
    },
    bgImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    contentWrapper: {
      padding: 20,
      [theme.breakpoints.up('md')]: {
        padding: 60,
        height: '100%'
      }
    },
    gutter: {
      marginBottom: '1rem'
    },
    partnersWrapper: {
      padding: 20,
      paddingTop: 40,
      paddingBottom: 40,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }
    },
    partner: {
      maxWidth: '100%',
      maxHeight: '150px'
    }
  })
)

const About = () => {
  const styles = useStyles()

  return (
    <FrontsiteLayout>
      <section>
        <Grid container className={styles.gridContainer}>
          <Grid item xs={12} md={6}>
            <img
              className={styles.bgImage}
              src="About Masungi 360.jpg"
              alt="About Masungi 360"
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            className={styles.contentWrapper}
            direction="column"
            justify="center"
          >
            <Box mb={4}>
              <Typography component="h2" variant="h4">
                Overview
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant="h5" className={styles.gutter}>
                About Masungi 360°: Virtual Field Trips for Young
                Conservationists
              </Typography>
              <Typography className={styles.gutter}>
                Masungi 360 is the first digital learning platform that offers
                an <b>immersive, educational experience</b> on Philippine native
                and <b>endemic biodiversity</b>, featuring{' '}
                <b>real-life stories</b> of rural communities, and{' '}
                <b>challenges of conservation</b> on the ground. Shot with
                drones and 360* cameras, it is especially made for the Filipino
                youth by Filipino experts and conservation leaders.
              </Typography>
              <Typography className={styles.gutter}>
                Our mission is to bring <b>local conservation education</b> to 2
                million Filipino students despite the pandemic to prevent future
                pandemics. We envision a Filipino youth that is aware of our
                unique environmental challenges and <b>actively participates</b>{' '}
                in solutions in addressing these challenges.
              </Typography>
              <Typography className={styles.gutter}>
                The platform offers students a gamified learning experience
                through the three virtual learning trails that utilize content
                made and approved by field experts. It also offers schools a
                student management system to help them monitor and track their
                student's progress.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container className={styles.gridContainer}>
          <Grid item xs={12} md={6}>
            <img
              className={styles.bgImage}
              src="About Masungi Georeserve.jpg"
              alt="About Masungi Georeserve"
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            className={styles.contentWrapper}
            direction="column"
            justify="center"
          >
            <Box>
              <Typography variant="h5" className={styles.gutter}>
                ABOUT MASUNGI GEORESERVE
              </Typography>
              <Typography className={styles.gutter}>
                Masungi Georeserve is an award-winning conservation initiative
                protecting historic limestone formations and hundreds of
                biodiversity against many threats. The project also uses
                geotourism to sustain conservation efforts and raise awareness
                for the importance of the landscape.
              </Typography>
              <Typography className={styles.gutter}>
                In 2018, it received a special commendation for innovation in
                conservation financing and resourcing from the International
                Union for the Conservation of Nature (IUCN) and the United
                Nations Development Programme (UNDP) at the UN Convention on
                Biological Diversity during the COP-14 Meetings in Sharm el
                Sheikh, Egypt. It was also recognized as one of the top
                sustainable tourism projects globally by both the World Travel
                and Tourism Council and the United Nations World Tourism
                Organization in 2019.
              </Typography>
              <Typography className={styles.gutter}>
                Since 1996, the Masungi Georeserve Foundation’s team, through
                its mother organization Blue Star Development Corporation, has
                protected the limestone formations from quarrying, illegal
                logging, and large-scale land speculation. After 20 years of
                conservation efforts, it is now a thriving wildlife sanctuary
                with site-specific species such as the Hypselostoma Latispira
                Masungiensis microsnail.
              </Typography>
              <Typography className={styles.gutter}>
                The foundation has worked with different organizations and
                communities in providing 300 households with access to clean,
                potable water, planting more than 57, 000 native trees in the to
                restore degraded forests, and engaging around 100 local park
                rangers in meaningful work.
              </Typography>
              <Typography>
                Because of the outstanding results of its conservation model,
                the Philippines’ Department of Environment and Natural Resources
                challenged Masungi to reforest another 2, 700 hectares of
                denuded landscape surrounding the Georeserve. Currently, we act
                as stewards over the Masungi Geopark Project where the new
                reforestation site is located. The project aims to restore the
                water supply for over 6, 000 households in the surrounding
                communities, bring back native biodiversity, and serve as a
                local carbon sink. As an integral part of the Upper Marikina
                River Basin Protected Landscape, the Masungi Geopark Project
                also aims to help absorb stormwater run-offs from the Sierra
                Madre Mountains.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </section>

      <section className={styles.section}>
        <div className={`${styles.contentWrapper} ${styles.partnersWrapper}`}>
          <Box pb={4} textAlign="center">
            <Typography component="h2" variant="h4">
              Our Partners
            </Typography>
          </Box>
          <Grid container spacing={10}>
            <Grid item xs={6} sm={4}>
              <Box textAlign="center">
                <img
                  src="partners-phflyinglabs.png"
                  alt="Philippines Flying Labs"
                  className={styles.partner}
                />
                <Typography>
                  The Philippines Flying Labs is part of the global Flying Labs
                  network which aims to promote the use of drones and technology
                  to solve local problems.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box textAlign="center">
                <img
                  src="partners-srdp.png"
                  alt="SRDP Consulting Inc."
                  className={styles.partner}
                />
                <Typography>
                  SRDP Consulting Inc. is an engineering consulting company that
                  specializes in Geographic Information Systems (GIS) and
                  Mapping.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box textAlign="center">
                <img
                  src="partners-lakbaykalikasan.png"
                  alt="Lakbay Kalikasan"
                  className={styles.partner}
                />
                <Typography>
                  Lakbay Kalikasan is a social enterprise providing Outbound
                  Education experiences to more than a million Filipino and
                  foreign students since 1999.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box textAlign="center">
                <img
                  src="partners-nikki.png"
                  alt="Nikki Inso"
                  className={styles.partner}
                />
                <Typography>
                  Nikki Inso is a freelance photographer and videographer
                  specializing in outdoor visuals, documentaries, and virtual
                  tours.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className={styles.section}>
        <Grid container className={styles.gridContainer}>
          <Grid
            item
            container
            xs={12}
            md={6}
            className={styles.contentWrapper}
            direction="column"
            justify="center"
          >
            <Box mb={4}>
              <Typography component="h2" variant="h4">
                Contact Us
              </Typography>
            </Box>
            <Box mb={4} display="flex">
              <EmailOutlinedIcon />
              <Box ml={2}>
                <Typography>
                  <a href="mailto:learn@masungigeoreserve.com">
                    learn@masungigeoreserve.com
                  </a>
                </Typography>
              </Box>
            </Box>
            <Box mb={10} display="flex" alignItems="center">
              <PhoneOutlinedIcon />
              <Box ml={2}>
                <Typography>
                  <a href="tel:+639088887002">+63 (908) 888 70 02</a>
                  <br />
                  <a href="tel:+639951869911">+63 (995) 186 99 11</a>
                </Typography>
              </Box>
            </Box>
            <Box>
              <Button href="https://www.facebook.com/masungigeoreserve">
                <img height="36" src="icon-facebook.png" alt="Facebook" />
              </Button>
              <Button href="https://www.instagram.com/masungigeoreserve/?hl=en">
                <img height="36" src="icon-instagram.png" alt="Instagram" />
              </Button>
              <Button href="https://twitter.com/MasungiGeo">
                <img height="36" src="icon-twitter.png" alt="Twitter" />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              className={styles.bgImage}
              src="Contact Us.jpg"
              alt="About Masungi 360"
            />
          </Grid>
        </Grid>
      </section>
    </FrontsiteLayout>
  )
}

export default About
